import React, { useState } from "react";
import { string, arrayOf, any } from "prop-types";
import { Row, Col, Affix } from "antd";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import CtaPrimary from "../Buttons/primary-cta";
import InnerNavStyles from "./InnerNavStyles";

const InnerNav = ({ links, getDemoUrl, ctaTitle }) => {
  const [active, setActive] = useState(links[0]);
  const handleChanges = (item) => {
    setActive(item);
  };
  return (
    <Affix offsetTop={0}>
      <Row className="inner-navigation">
        <Col span={24}>
          <div
            className="inner-nav"
            style={{ gridTemplateColumns: `repeat(${links.length + 1}, 1fr)` }}
          >
            {links.map((link, idx) => (
              <AnchorLink
                stripHash
                key={`link__${idx.toString()}`}
                to={`/restaurant-pos#${link.url}`}
                onAnchorLinkClick={() => handleChanges(link)}
              >
                <p className={active === link ? "inner-nav__active" : ""}>
                  {link.itemName}
                </p>
              </AnchorLink>
            ))}
            <CtaPrimary target={getDemoUrl} ctaTitle={ctaTitle} />
          </div>
        </Col>
      </Row>

      <InnerNavStyles />
    </Affix>
  );
};

InnerNav.propTypes = {
  links: arrayOf(string),
  getDemoUrl: string,
  ctaTitle: string,
};
InnerNav.defaultProps = {
  links: "",
  getDemoUrl: "/demo",
  ctaTitle: "Learn more",
};

export default InnerNav;
