import React from "react";
import { viewports, colors } from "../../style-vars";

const { smOrSmaller, mdOrSmaller } = viewports;
const { primary, black, white } = colors;

const InnerNavStyles = () => (
  <style jsx global>{`
    .inner-navigation {
      height: 72px;
      background: ${white} !important;
      margin-top: 15px;
    }
    .inner-nav {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: ${white} !important;
      z-index: 2;
      height: 100%;
      width: 100%;
    }
    .inner-nav p {
      padding-top: 8px;
      color: ${black};
    }
    .inner-nav a {
      font-size: 16px;
      line-height: 1.7;
    }
    @media ${mdOrSmaller} {
      .inner-nav {
        padding: 10px;
        text-align: center;
      }
      .inner-nav p {
        max-width: 80px;
      }
      .inner-nav a {
        line-height: 0.95;
      }
    }
    @media ${smOrSmaller} {
      .inner-nav {
        padding: 10px;
        overflow: hidden;
        overflow-x: scroll;
        grid-gap: 64px;
        justify-content: start;
      }
      .inner-nav a {
        line-height: 0.95;
      }
      .inner-nav p {
        padding-top: 0px;
        line-height: 1.4;
        margin-bottom: 0;
      }
    }
    .inner-nav__active {
      position: relative;
    }
    .inner-nav__active:before {
      position: absolute;
      left: 0;
      bottom: calc(-75% - 1px);
      width: 100%;
      height: 3px;
      content: " ";
      background-color: ${primary};
    }
    @media ${smOrSmaller} {
      .inner-nav__active:before {
        bottom: calc(-30% - 1px);
      }
    }
  `}</style>
);

export default InnerNavStyles;
